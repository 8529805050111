import React, { useContext, useState } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import Article from "./Article"
import { BrinkContext } from "../context/BrinkContext"

const Container = styled.ul`
  max-width: ${containerSmallMaxWidth};
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  position: relative;
`

const Anchor = styled.div`
  position: absolute;
  top: -9rem;

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const Text = styled.h3`
  text-transform: initial;
  margin: 3rem 0 0;
  font-family: ${(p) => p.faqRows.length > 0 && "Helvetica Neue Condensed"};
  font-size: ${(p) => p.faqRows.length > 0 && "1.6rem"};
  line-height: ${(p) => p.faqRows.length > 0 && "1.9rem"};
`

const SectionArticles = ({ faqRows, selectedSection }) => {
  const { languageCode } = useContext(BrinkContext)
  const [currentArticle, setCurrentArticle] = useState(null)

  if (!faqRows) return null

  return (
    <Container>
      <Anchor className="articles" />
      {faqRows.map((row) => {
        return row._type === "faqTabItem"
          ? row.category === selectedSection && (
              <Article
                key={uuidv4()}
                article={row}
                expanded={row._id === currentArticle}
                setCurrentArticle={setCurrentArticle}
                faqRows={faqRows}
              />
            )
          : row._type === "textWidget" && (
              <Text key={uuidv4()} faqRows={faqRows}>
                {row.title[languageCode] || row.title.en}
              </Text>
            )
      })}
    </Container>
  )
}

export default SectionArticles
