import React from "react"
import styled from "styled-components"
import { MEDIA_MIN_MEDIUM } from "../../constants"

const H2 = styled.h2`
  text-align: center;
  font-size: 1.6rem;
  position: relative;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3rem;
  }

  span {
    display: inline-block;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
    letter-spacing: 0.05rem;

    i {
      color: ${(p) => p.theme.colors.primary};
    }
  }
`

const LineHeading = ({ children, ...other }) => {
  if (!children) return null

  return (
    <H2 {...other}>
      <span>{children}</span>
    </H2>
  )
}

export default LineHeading
