import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import BlockContent from "@sanity/block-content-to-react"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../../constants"
import Link from "../Link"
import { BrinkContext } from "../../components/context/BrinkContext"

const FaqArticle = styled.li`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.5rem;
  margin: 0;

  > div {
    width: 100%;
  }

  ${MEDIA_MIN_MEDIUM} {
  }
`

const Title = styled.h4`
  width: 100%;
  position: relative;
  cursor: pointer;
  margin: 0;
  text-align: left;

  p {
    margin: 0 1rem 0 0;
    display: inline;
    font-variation-settings: "wght" 500;
  }
`

const Body = styled.div`
  width: 100%;
  display: ${(p) => (p.open ? "block" : "none")};
 
`

const Answer = styled.div`
  padding: 0.5rem 0 2rem 2.2rem;
  width: 100%;
  background: ${(p) => p.theme.colors.background};
  text-align: left;

  p {
    margin: 1rem 0;
    font-family: ${(p) =>
      p.faqRows.length > 0 && "Helvetica Neue Light Condensed"};
    font-size: ${(p) => p.faqRows.length > 0 && "1.6rem"};
    line-height: ${(p) => p.faqRows.length > 0 && "1.9rem"};
  }

  li {
    letter-spacing: 0.05rem;
  }

  a {
    color: ${(p) => p.theme.colors.black};
    text-decoration: underline
  }

  div {
    width: 100%;
  }

  img {
    max-width: 100%;
  }
`

const UpdatedAt = styled.div`
  font-size: 2rem;
  color: ${(p) => p.theme.colors.grey};
  font-family: ${(p) => p.faqRows.length > 0 && "Helvetica Neue Condensed"};
  font-size: ${(p) => p.faqRows.length > 0 && "1.6rem"};
  line-height: ${(p) => p.faqRows.length > 0 && "1.9rem"};
`

const Article = ({ article, expanded, setCurrentArticle, faqRows }) => {
  const { t } = useTranslation("translations")
  const { languageCode, currentStore } = useContext(BrinkContext)
  const localizedRawText =
    article._rawText?.[languageCode] || article._rawText?.en

  useEffect(() => {
    async function loadWithLocale() {
      await import(`dayjs/locale/${languageCode}`)
    }
    loadWithLocale()
  }, [currentStore, languageCode])

  if (!article) return null

  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = {} } = mark.reference
        return <Link to={`/${slug.current}/`}>{children}</Link>
      },
      link: ({ mark, children }) => (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    }
  }

  return (
    <FaqArticle>
      <Title
        onClick={() => {
          expanded ? setCurrentArticle(null) : setCurrentArticle(article._id)
        }}
      >
        {faqRows.length > 0 && expanded ? <p>−</p> : <p>+</p>}
        {article?.title[languageCode] || article?.title.en}
      </Title>
      <Body open={expanded}>
        <Answer faqRows={faqRows}>
          {localizedRawText?.text.map((text) => (
            <BlockContent
              key={uuidv4()}
              blocks={text}
              serializers={serializers}
            />
          ))}
          <UpdatedAt faqRows={faqRows}>
            {t("Last updated on")}:{" "}
            {dayjs(article._updatedAt)
              .locale(languageCode)
              .format("MMMM DD, YYYY")}
          </UpdatedAt>
        </Answer>
      </Body>
    </FaqArticle>
  )
}
export default Article
