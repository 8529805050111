import React, { useState, useContext } from "react"
import styled from "styled-components"
import {
  containerSmallMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../constants"
import Layout from "../components/Layout"
import SectionArticles from "../components/faq/SectionArticles"
import { graphql } from "gatsby"
import LineHeading from "../components/ui/LineHeading"
import { BrinkContext } from "../components/context/BrinkContext"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  padding: 1rem;
  text-align: left;
  padding: 5rem 1rem 1rem;
  font-family: "Helvetica Neue Light Condensed";
  font-size: 1.6rem;
  line-height: 1.9rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 5rem 1rem 1rem;
  }

  ${MEDIA_MIN_LARGE} {
    padding: 5rem 0 10rem calc(100% / 4);
  }
`

const Title = styled(LineHeading)`
  text-align: left;
  font-size: 3rem;
  line-height: 2.4rem;
  margin-bottom: 0;

  span {
    padding: 0;
  }
`

const FaqPage = ({ data: { sanityFaqPage }, pageContext }) => {
  const [selectedSection] = useState("faq")
  const { title, faqTabItems } = sanityFaqPage
  const { languageCode } = useContext(BrinkContext)

  return (
    <Layout meta={{ title: title.en }} invertedHeader pageContext={pageContext}>
      <Container>
        <Title>{title[languageCode] || title.en}</Title>
        <SectionArticles
          faqRows={faqTabItems}
          selectedSection={selectedSection}
        />
      </Container>
    </Layout>
  )
}

export default FaqPage

export const query = graphql`
  query {
    sanityFaqPage {
      faqTabItems {
        ... on SanityFaqTabItem {
          _type
          title {
            sv
            fr
            en
            de
          }
          _rawText(resolveReferences: { maxDepth: 10 })
          _id
          category
          _updatedAt
        }
        ... on SanityTextWidget {
          _type
          id
          name
          title {
            de
            en
            fr
            sv
          }
        }
      }
      title {
        de
        en
        fr
        sv
      }
    }
  }
`
